import React from "react"

import {
  BalanceOfFunds,
  Car,
  CardInSuitcase,
  Cashback,
  FireExtinguisher,
  InstallmentPlan,
  MoneyTransfer,
  ThreeCards,
  Roubles,
  Redlabel,
  RedCar,
  Months,
  ThreePercentCoins,
} from "./images"

import type { BenefitsItem } from "./types"

import * as styles from "./moreBenefitsRedisign.module.scss"

const hintClassNames = {
  // 10 МЕСЯЦ

  c1: {
    xs: {
      left: 20,
      top: -30,
    },
    sm: {
      left: 22,
      top: -40,
    },
    md: {
      top: -8,
      left: -16,
    },

    lg: {
      left: 24,
      top: -45,
    },
  },
  // ДО 12%

  c2: {
    xs: {
      left: 20,
      top: -30,
    },
    sm: {
      left: 20,
      top: -30,
    },
    md: {
      left: 22,
      top: -43,
    },

    lg: {
      left: 24,
      top: -45,
    },
  },
  // КЭШБЭК

  c3: {
    xs: {
      left: 20,
      top: -30,
    },
    sm: {
      top: -5,
      left: -50,
    },
    md: {
      top: -10,
      left: -75,
    },

    lg: {
      left: 24,
      top: -45,
    },
  },
  // ПЕРЕВОДЫ
  c4: {
    xs: {
      left: 20,
      top: -30,
    },
    sm: {
      left: 22,
      top: -40,
    },
    md: {
      left: 22,
      top: -43,
    },

    lg: {
      left: 24,
      top: -45,
    },
  },
}

const pkwItems = [
  {
    Img: InstallmentPlan,
    alt: "ten months",
    title: (
      <>
        Увеличенная рассрочка
        <b>
          <br /> от 10 месяцев
        </b>
        <br /> у всех партнеров
      </>
    ),
    tooltipHint:
      "Увеличенная рассрочка у всех партнеров. Совершайте покупки у любых партнеров Халвы со сроком рассрочки от 10 месяцев и больше.",
    hintClassName: hintClassNames.c1,
  },
  {
    Img: BalanceOfFunds,
    alt: "twelve percents",
    title: (
      <>
        <b>
          До 17 % на остаток <br />
        </b>
        собственных средств для новых клиентов
      </>
    ),
    tooltipHint: (
      <>
        Для получения процентов на остаток, подключите&nbsp;
        <a
          className={styles.link}
          target="_blank"
          href="https://halvacard.ru/halvadesyatka/"
          rel="noreferrer"
        >
          подписку «Халва.Десятка»
        </a>
        . 17% - первые 3 месяца после выдачи карты, далее 15% (без подписки % не начисляются).
      </>
    ),
    hintClassName: hintClassNames.c2,
  },
  {
    Img: Cashback,
    alt: "cashback",
    title: (
      <>
        Кэшбэк <br /> <b>до 10% </b>
      </>
    ),
    tooltipHint:
      "В том числе гарантированный кэшбэк 10% за каждую поездку на такси «Яндекс Go». С подпиской «Яндекс Плюс» – еще до 10% кэшбэка от сервиса.",
    hintClassName: hintClassNames.c3,
  },
  {
    Img: ThreePercentCoins,
    alt: "three percent coins",
    title: (
      <>
        <b>До +3%</b> <br /> к ставке вклада
      </>
    ),
    tooltipHint: (
      <>
        При ежемесячном выполнении условия: от&nbsp;5 покупок по&nbsp;Халве на&nbsp;общую сумму
        от&nbsp;10&nbsp;000 руб. +3% годовых добавляется к&nbsp;ставке вклада, открытого
        на&nbsp;24&nbsp;или&nbsp;36 месяцев (по&nbsp;другим срокам +1%).
      </>
    ),
    hintClassName: hintClassNames.c4,
  },
]

const pkwItemsNewHint = [
  {
    Img: InstallmentPlan,
    alt: "ten months",
    title: (
      <>
        Увеличенная рассрочка
        <b>
          <br /> от 10 месяцев
        </b>
        <br /> у всех партнеров
      </>
    ),
    tooltipHint:
      "Увеличенная рассрочка у всех партнеров. Совершайте покупки у любых партнеров Халвы со сроком рассрочки от 10 месяцев и больше.",
    hintClassName: hintClassNames.c1,
  },
  {
    Img: BalanceOfFunds,
    alt: "twelve percents",
    title: (
      <>
        <b>
          До 17 % на остаток <br />
        </b>
        собственных средств для новых клиентов
      </>
    ),
    tooltipHint: (
      <>
        Для получения процентов на остаток, подключите&nbsp;
        <a
          className={styles.link}
          target="_blank"
          href="https://halvacard.ru/halvadesyatka/"
          rel="noreferrer"
        >
          подписку «Халва.Десятка»
        </a>
        . 17% - первые 3 месяца после выдачи карты, далее 15% (без подписки % не начисляются).
      </>
    ),
    hintClassName: hintClassNames.c2,
  },
  {
    Img: Cashback,
    alt: "cashback",
    title: (
      <>
        Кэшбэк <br /> <b>до 10% </b>
      </>
    ),
    tooltipHint:
      "При оплате сервисом Рау, Платёжным стикером или в Интернете. В том числе гарантированный кэшбэк 10% за каждую поездку на такси «Яндекс Go».",
    hintClassName: hintClassNames.c3,
  },
  {
    Img: ThreePercentCoins,
    alt: "three percent coins",
    title: (
      <>
        <b>До +3%</b> <br /> к ставке вклада
      </>
    ),
    tooltipHint: (
      <>
        При ежемесячном выполнении условия: от&nbsp;5 покупок по&nbsp;Халве на&nbsp;общую сумму
        от&nbsp;10&nbsp;000 руб. +3% годовых добавляется к&nbsp;ставке вклада, открытого
        на&nbsp;24&nbsp;или&nbsp;36 месяцев (по&nbsp;другим срокам +1%).
      </>
    ),
    hintClassName: hintClassNames.c4,
  },
]

const refinItems = [
  {
    Img: ThreeCards,
    alt: "three cards",
    title: (
      <>
        Рефинансирование кредитных карт
        <br />
        <b>любых банков</b>
      </>
    ),
  },
  {
    Img: FireExtinguisher,
    alt: "fire extinguisher",
    title: (
      <>
        <b>Просто и быстро</b>
        <br />
        погасим задолженности за&nbsp;вас
      </>
    ),
  },
  {
    Img: CardInSuitcase,
    alt: "card in suitcase",
    title: (
      <>
        Возможность <b>досрочного погашения</b> без&nbsp;комиссий
      </>
    ),
  },
  {
    Img: Car,
    alt: "car",
    title: (
      <>
        <b>Дистанционное оформление</b>
        <br />и бесплатная доставка Халвы
      </>
    ),
  },
]

const inviteFriendItems = [
  {
    Img: InstallmentPlan,
    alt: "ten months",
    title: (
      <>
        Увеличенная рассрочка
        <b>
          <br /> от 10 месяцев
        </b>
        <br /> у всех партнеров
      </>
    ),
    tooltipHint:
      "Увеличенная рассрочка у всех партнеров. Совершайте покупки у любых партнеров Халвы со сроком рассрочки от 10 месяцев и больше.",
    hintClassName: hintClassNames.c1,
  },
  {
    Img: BalanceOfFunds,
    alt: "twelve percents",
    title: (
      <>
        <b>
          До 17% на остаток <br />
        </b>
        собственных средств для новых клиентов
      </>
    ),
    tooltipHint:
      "До 17% на остаток собственных средств первые три месяца при подключении подписки «Халва.Десятка».",
    hintClassName: hintClassNames.c2,
  },
  {
    Img: Cashback,
    alt: "cashback",
    title: (
      <>
        Кэшбэк <br /> <b>до 10% </b>
      </>
    ),
    tooltipHint:
      "При оплате сервисом Рау, Платёжным стикером или в Интернете. В том числе кэшбэк 10% за каждую поездку на такси «Яндекс Go».",
    hintClassName: hintClassNames.c3,
  },
  {
    Img: MoneyTransfer,
    alt: "money transfer",
    title: (
      <>
        <b>Бесплатное</b> <br /> обслуживание
      </>
    ),
    tooltipHint: "0 руб. за выпуск, перевыпуск и использование карты.",
    hintClassName: hintClassNames.c4,
  },
]

const btsAuto = [
  {
    Img: RedCar,
    alt: "car",
    title: (
      <>
        Главный приз – <br /> <b>Автомобиль</b>
      </>
    ),
  },
  {
    Img: Months,
    alt: "36 months",
    title: (
      <>
        Рассрочка <br /> <b>до 36 месяцев</b>
      </>
    ),
  },
  {
    Img: Roubles,
    alt: "5000 roubles",
    title: (
      <>
        <b>5 000 баллов</b> <br /> кэшбэка
      </>
    ),
  },
  {
    Img: Redlabel,
    alt: "halva label",
    title: (
      <>
        <b>Бонусы&nbsp;от&nbsp;Халвы</b> <br /> и партнеров
      </>
    ),
  },
]

export const items: Record<
  "pkw" | "refin" | "pkwItemsNewHint" | "inviteFriendItems" | "btsAuto",
  BenefitsItem[]
> = {
  pkw: pkwItems,
  refin: refinItems,
  pkwItemsNewHint,
  inviteFriendItems,
  btsAuto,
}
